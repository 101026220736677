<template>
  <!-- Navigation -->
  <nav id="globalNavbar" class="navbar navbar-expand-lg navbar-custom fixed-top" :class="setClases()">
    <!-- Text Logo - Use this if you don't have a graphic logo -->
    <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Evolo</a> -->

    <!-- Image Logo -->
    <a class="navbar-brand logo-image" href="/"><img :src="logoUrl" alt="alternative" /></a>

    <!-- Mobile Menu Toggle Button -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <font-awesome-icon class="navbar-toggler-awesome" icon="bars" />
      <font-awesome-icon class="navbar-toggler-awesome" icon="times" />
    </button>
    <!-- end of mobile menu toggle button -->

    <div class="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link page-scroll" href="/#header">{{ navLinks.home }} <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link page-scroll" href="/#services">{{ navLinks.services }}</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link page-scroll" href="#pricing">{{ navLinks.pricing }}</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link page-scroll" href="/#request">{{ navLinks.request }}</a>
        </li>

        <!-- Dropdown Menu -->
        <!-- <li class="nav-item">
          <a class="nav-link page-scroll" href="#about" role="button" aria-haspopup="true"
            aria-expanded="false">{{ navLinks.about }}</a>
        </li> -->
        <!-- end of dropdown menu -->

        <li class="nav-item">
          <a class="nav-link page-scroll" href="/#contact">{{ navLinks.contact }}</a>
        </li>

        <li class="nav-item">
          <a class="nav-link page-scroll" href="/veeam">{{ navLinks.veeam }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link page-scroll" href="/sophos">{{ navLinks.sophos }}</a>
        </li>
      </ul>
      <span class="nav-item social-icons">
        <span class="fa-stack">
          <a :href="navLinks.fbLink">
            <font-awesome-icon class="fa-stack-2x" :icon="['fab', 'facebook']" />
          </a>
        </span>
        <span class="fa-stack">
          <a :href="navLinks.twitterLink">
            <font-awesome-icon class="fa-stack-2x" :icon="['fab', 'twitter']" />
          </a>
        </span>
      </span>
    </div>
  </nav>
  <!-- end of navbar -->
  <!-- end of navigation -->
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    offsetTop: {
      type: Number,
    },
    logoUrl: {
      type: String,
    },
    navLinks: {
      type: Object,
    }
  },
  methods: {
    setClases() {
      const classes = [];
      if (this.offsetTop > 60) {
        classes.push('top-nav-collapse');
      }
      return classes;
    },
  },
};
</script>

<style src="./Navbar.css">

</style>
