import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// FontAwesome Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
 

import VueGtag from "vue-gtag";

import VueMeta from 'vue-meta'

library.add(fas);
library.add(fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

 


Vue.use(VueGtag, {
  config: { id: "279935237" },
  pageTrackerScreenviewEnabled: true,
  includes: [{ id: "AW-788548443" }]
}, router);




Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  render: function (h) { return h(App) },
}).$mount('#app');
