module.exports = [{
    path: '/',
    name: 'Home',
    component: function () {
        return import('../views/Home.vue')
    }
},
{
    path: '/terms',
    name: 'terms',
    component: function () {
        return import('../views/terms.vue')
    }
},
{
    path: '/privacy',
    name: 'privacy',
    component: function () {
        return import('../views/privacy.vue')
    }
},
{
    path: '/veeam',
    name: 'veeam',
    component: function () {
        return import('../views/veeam.vue')
    }
},
{
    path: '/sophos',
    name: 'sophos',
    component: function () {
        return import('../views/sophos.vue')
    }
}
]